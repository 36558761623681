import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useGetValves } from '../../hooks/useValves';

function ValveIdInput({ handleCallback}) {

    ValveIdInput.propTypes = {
        handleCallback: PropTypes.func
    }

    const { valves } = useGetValves();

    // Handle change of the 'valveId' selection
    const handleValveIdChange = (selectedValves) => {

        if (selectedValves.length > 0) {
            let valveId = selectedValves[0].tag;
            handleCallback(valveId);
        } else {
            handleCallback("");
        }
    }

    // Handle change of typing in the text box
    const handleTypedValveChange = (text) => {
        handleCallback(text);
    }

    return (
        <Form.Group className="mb-3" controlId="valveId">
            <Typeahead
                id="valveIdAutoComplete"
                labelKey="tag"
                onChange={handleValveIdChange}
                onInputChange={handleTypedValveChange}
                options={valves}
                placeholder="Enter valve ID"
            />
        </Form.Group>
    );
}

export default ValveIdInput;