import React from 'react';
import DataTable from './DataTable/datatable.component';
import { useGetTasks } from '../hooks/useTasks';

function ActiveTasksTable() {

    const { tasks, loading } = useGetTasks();

    var initialState = undefined;

    var columns = [
        {
            Header: 'Task',
            accessor: 'task',
            filter: 'fuzzyText'
        },
        {
            Header: 'Order No.',
            accessor: 'orderNumber',
            filter: 'fuzzyText'
        },
        {
            Header: 'Type',
            accessor: 'taskType',
            filter: 'fuzzyText'
        },
        {
            Header: 'Material',
            accessor: 'material',
            filter: 'fuzzyText'
        },
        {
            Header: 'Status',
            accessor: 'status',
            filter: 'fuzzyText'
        },
        {
            Header: 'Source',
            accessor: 'sources',
            filter: 'fuzzyText'
        },
        {
            Header: 'Dest.',
            accessor: 'destinations',
            filter: 'fuzzyText'
        },
        {
            Header: 'Flow',
            accessor: 'targetFlow',
            filter: 'fuzzyText'
        },
        {
            Header: 'Vol.',
            accessor: 'taskVolume',
            filter: 'fuzzyText'
        }
    ];

    if (loading) {
        return (
            <p><em>Loading...</em></p>
        );
    }

    if (tasks != null && tasks.length > 0) {
        return (
            <DataTable tableColumns={columns} tableData={tasks} initialState={initialState} />
        );
    } else {
        return (<h5>No jobs available</h5>);
    }
}

export default ActiveTasksTable;