import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap';
import TaskTable from '../TaskTable';
import { useGetTask } from '../../hooks/useTasks';
import { useCheckoutJob, useCompleteJob } from '../../hooks/useJobs';
import { useValveStates } from '../../hooks/useValveStates';

function ChangeStateDialogContent({ job, updateJob, closeDialog }) {

    ChangeStateDialogContent.propTypes = {
        job: PropTypes.object,
        updateJob: PropTypes.func,
        closeDialog: PropTypes.func
    }

    const { valveStates } = useValveStates();
    const { completeJob } = useCompleteJob();
    const { undoCheckoutJob } = useCheckoutJob();
    const { task, loading, error } = useGetTask(job.taskName);
    const [formValidated, setFormValidated] = useState(false);


    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        setFormValidated(true);

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            job.valveStateId = event.target.newValveState.value;
            completeJobAndClose();
        }
    };

    const completeJobAndClose = async () => {

        job = await completeJob(job)
        updateJob(job);
        closeDialog();

        // add error handling 
    }

    const undoCheckoutJobAndClose = async () => {

        job = await undoCheckoutJob(job)

        updateJob(job);
        closeDialog();

        // add error handling for task and undo checkout
    }

    // wait for task to load
    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    let modalBody = (
        <>
            <div className="my-3">
                <p>Required Action: <b>{job.action}</b></p>
                <div className="mb-4">
                    <TaskTable task={task} />
                </div>
            </div>
            <Row>
                <Col>
                    Current State: <b>{job.action === "NoActionRequired" ? "Not known" : job.action === "Close" ? "Open" : "Closed"}</b>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Select name="newValveState" required>
                            <option key="0" value="">Set state...</option>
                            {
                                valveStates && valveStates.map((s, i) => {
                                    return <option key={i + 1} value={s.id}>{s.name}</option>
                                })
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Set the state to complete the job
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        </>
    )

    return (
        <>
            <Form noValidate validated={formValidated} onSubmit={handleSubmit}>
                <Modal.Body style={{ minHeight: '300px' }}>
                    {modalBody}
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button variant="warning" style={{ color: 'white' }} onClick={undoCheckoutJobAndClose}>Undo Checkout</Button>
                    <Button variant="primary" type="submit">Finish</Button>
                </Modal.Footer>
            </Form>
        </>
    );
}

export default ChangeStateDialogContent;