import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { QRCodeScanner, VIBRATE_DURATION } from '../QRCodeScanner';
import NoQrScanReason from './NoQrScanReason';
import ValveIdInput from './ValveIdInput';
import { useValveStates } from '../../hooks/useValveStates';
import { useCreateAdHocJob } from '../../hooks/useJobs';
import { useEffect } from 'react';

function AdHocJobDialog({ show, closeDialog, jobs, setJobs }) {

    AdHocJobDialog.propTypes = {
        show: PropTypes.bool,
        closeDialog: PropTypes.func,
        jobs: PropTypes.array,
        setJobs: PropTypes.func
    }

    const [job, setJob] = useState({});
    const { valveStates } = useValveStates();

    const [qrScanResult, setQrScanResult] = useState();
    const [qrScanResultValue, setQrScanResultValue] = useState();
    const [qrScanSuccess, setQrScanSuccess] = useState();
    const [qrScanMessage, setQrScanMessage] = useState();

    const [noQrScanReason, setNoQrScanReason] = useState({});

    const [formValidated, setFormValidated] = useState(false);
    const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
    const { createAdHocJob } = useCreateAdHocJob();

    useEffect(() => {
        if (qrScanResult && qrScanResult?.text !== qrScanResultValue) {

            navigator.vibrate(VIBRATE_DURATION);

            // New qr code has been received
            console.log("New QR code scanned: " + qrScanResult?.text);
            setQrScanResultValue(qrScanResult?.text);

            // Clear the no scan reason
            setNoQrScanReason({ selectedReason: "", otherReason: "" });

            // Get valveId from the qr code result
            var valveId = getValveIdFromQrCode(qrScanResult?.text);
            setQrScanSuccess(valveId !== "");

            // Set results
            if (valveId !== "") {
                setQrScanMessage(`Valid QR Code for valve ${valveId}`);
                setJob({ ...job, valveId: valveId });
                setCreateButtonDisabled(false);
            }
            else {
                setQrScanMessage("Invalid QR Code");
                setCreateButtonDisabled(true);
            }
        }
    }, [qrScanResult]);

    const handleQrResult = (result, error) => {
        if (!!result) {
            setQrScanResult(result);
        }
    }

    // Qr code format is {siteCode}/Valves/{valveId}
    // Check if the valveId can be extracted and return it
    const getValveIdFromQrCode = (qrCodeValue) => {
        let parts = qrCodeValue.split('/');
        let valveId = parts[2];

        return valveId ?? "";
    }

    // Disable next button if no reason is selected and valveId has not been set
    const validateForm = (reason, newValveId) => {

        if (reason.selectedReason === "" || (reason.selectedReason === "Other" && reason.otherReason === "")) {
            setCreateButtonDisabled(true);
        } else if (newValveId === "") {
            setCreateButtonDisabled(true);
        } else {
            setCreateButtonDisabled(false);
        }
    }

    // A reason for not scanning is selected
    const handleNoScanReasonCallback = (reason) => {

        // Reason has been selected so reset the qr scan results
        setQrScanResult(undefined);
        setQrScanSuccess(undefined);
        setQrScanMessage(undefined);

        // Set the no scan reason in the job
        setJob({ ...job, noQrScanReason: reason.selectedReason === "Other" ? reason.otherReason : reason.selectedReason });
        setNoQrScanReason(reason);

        validateForm(reason, job.valveId);
    }

    // Valve Id is selected
    const handleValveIdInputCallback = (valveTag) => {

        // Valve Id has been typed so reset the qr scan results
        setQrScanResult(undefined);
        setQrScanSuccess(undefined);
        setQrScanMessage(undefined);

        setJob({ ...job, valveId: valveTag });

        validateForm(noQrScanReason, valveTag);
    }

    // Handle submission of the change state form
    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        setFormValidated(true);

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            job.valveStateId = event.target.newValveState.value;
            createAdHocJobAndClose();
        }
    };

    const createAdHocJobAndClose = async () => {

        var createdJob = await createAdHocJob(job);
        setJobs([...jobs, createdJob]);
        // reset job and dialog page
        setJob(undefined);
        setQrScanResult(undefined);
        setQrScanSuccess(undefined);
        setQrScanMessage(undefined);
        setNoQrScanReason({});
        setFormValidated(false);
        closeDialog();
    }

    // If a Qr code has been scanned, show alert for if it was valid or not
    let qrResult = !qrScanSuccess ? "" : <Alert variant={qrScanSuccess ? "success" : "danger"} className="mt-2">{qrScanMessage}</Alert>

    return (
        <Modal centered show={show} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title>Ad hoc Change</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={formValidated} onSubmit={handleSubmit}>
                <Modal.Body style={{ minHeight: "300px" }}>
                    <p>This will create a record of the movement and send to L3 OMD</p>
                    <QRCodeScanner onScan={handleQrResult} />
                    {qrResult}
                    <NoQrScanReason reason={noQrScanReason} handleCallback={handleNoScanReasonCallback} />
                    {noQrScanReason.selectedReason && noQrScanReason.selectedReason !== "" &&
                        <ValveIdInput handleCallback={handleValveIdInputCallback} />
                    }
                    {((noQrScanReason.selectedReason && noQrScanReason.selectedReason !== "" && job.valveId) ||
                        (qrScanResult && job.valveId)) &&

                        <Form.Group>
                            <Form.Select name="newValveState" required>
                                <option key="0" value="">Set state...</option>
                                {
                                    valveStates && valveStates.map((s, i) => {
                                        return <option key={i + 1} value={s.id}>{s.name}</option>
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Set the state to complete the job
                            </Form.Control.Feedback>
                        </Form.Group>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" disabled={createButtonDisabled}>Create</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default AdHocJobDialog;