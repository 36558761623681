import { useState, useEffect } from 'react';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';
import useAlert from '../components/Alerts/useAlert';

export function useJobs() {

    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const { setAlert } = useAlert();

    useEffect(() => {
        getJobs();
    }, []);

    async function getJobs() {

        await fetchDataAuthenticated("api/jobs")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setJobs(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to get jobs", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { jobs, setJobs, loading }
}

export function useCheckoutJob() {

    const [loading, setLoading] = useState(true);
    const { setAlert } = useAlert();

    async function checkoutJob(job) {

        let updatedJob;

        await fetchDataAuthenticated(`api/jobs/${job.id}/checkout`, "POST", JSON.stringify(job))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                updatedJob = data;
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to checkout job", error.message);
            })
            .finally(() => {
                setLoading(false);
            })

        return updatedJob;
    }

    async function undoCheckoutJob(job) {

        let updatedJob;

        await fetchDataAuthenticated(`api/jobs/${job.id}/undoCheckout`, "POST", JSON.stringify(job))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                updatedJob = data;
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to undo checkout job", error.message);
            })
            .finally(() => {
                setLoading(false);
            })

        return updatedJob;
    }

    return { checkoutJob, undoCheckoutJob, loading }
}

export function useCompleteJob() {

    const [loading, setLoading] = useState(true);
    const { setAlert } = useAlert();

    async function completeJob(job) {

        await fetchDataAuthenticated(`api/jobs/${job.id}/complete`, "POST", JSON.stringify(job))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {

            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to complete job", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { completeJob, loading }
}

export function useCreateAdHocJob() {

    const [loading, setLoading] = useState(true);
    const { setAlert } = useAlert();

    async function createAdHocJob(job) {

        let createdJob;

        await fetchDataAuthenticated(`api/jobs/adhoc`, "POST", JSON.stringify(job))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                createdJob = data;
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to create ad hoc job", error.message);
            })
            .finally(() => {
                setLoading(false);
            })

        return createdJob;
    }

    return { createAdHocJob, loading }
}