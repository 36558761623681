import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import JobsTable from './JobsTable';
import AdHocJobDialog from './Dialogs/AdHocJobDialog';
import { useJobs } from '../hooks/useJobs';

function Jobs() {

    const { jobs, setJobs, loading, error } = useJobs();
    const [showAdHocJobDialog, setShowAdHocJobDialog] = useState(false);

    const openAdHocJobDialog = () => {
        setShowAdHocJobDialog(true);
    }

    const closeAdHocJobDialog = () => {
        setShowAdHocJobDialog(false);
    };

    return (
        <Container>
            <div className="d-flex justify-content-end mb-3">
                <Button variant="primary" className="mb-3" onClick={openAdHocJobDialog}>Ad hoc Change</Button>
            </div>
            <JobsTable jobs={jobs} setJobs={setJobs} loading={loading} error={error} />
            <AdHocJobDialog show={showAdHocJobDialog} closeDialog={closeAdHocJobDialog} jobs={jobs} setJobs={setJobs} />
        </Container>
    );
}

export default Jobs;

