export function useIdentifyEnvironment() {

    const url = window.location.href;

    if (url.includes("dev") || url.includes("localhost")) {
        return "Development";
    } else if (url.includes("acc")) {
        return "Acceptance";
    } else {
        return "Production";
    }
}