import React from 'react';
import { Navigate, Route } from 'react-router';
import { Routes } from 'react-router-dom';
import NavMenu from './components/NavMenu';
import Jobs from './components/Jobs';
import ActiveTasks from './components/ActiveTasks';
import Notifier from './services/NotifierService';
import NotifierContext from './components/Contexts/NotificationContext'
import { AlertProvider } from './components/Alerts/AlertContext';
import NotificationAlert from './components/Alerts/NotificationAlert';

import './custom.css'


function App() {

    return (
        <>
            <AlertProvider>
                <NotificationAlert />
                <NotifierContext.Provider value={Notifier()}>
                    <NavMenu />
                    <Routes>
                        <Route path='*' element={<Navigate to='/jobs' />} />
                        <Route path='/jobs' exact element={<Jobs />} />
                        <Route path='/active-tasks' exact element={<ActiveTasks />} />
                    </Routes>
                </NotifierContext.Provider>
            </AlertProvider>
        </>
    );
}

export default App;
