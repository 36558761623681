import React, { useState, useEffect } from 'react';
import { Navbar } from 'react-bootstrap';
import fetch from 'sync-fetch';
import './ConnectionStatus.css';
import NotifierContext from './Contexts/NotificationContext';

// SignOutButton Component returns a button that invokes a redirect logout when clicked
export default function ConnectionStatus(props) {

    const [currentStatus, setCurrentStatus] = useState("Unknown");

    var notifier = React.useContext(NotifierContext);

    useEffect(() => {

        notifier.on("omdGoStatusChanged", onStatusChange);

        return function () {
            notifier.off("omdGoStatusChanged", onStatusChange);
        }

    }, [currentStatus]);

    const onStatusChange = (eventData) => {
        var newStatus = eventData.detail.data;
        //console.log(newStatus);

        if (newStatus.isGood == true) {
            setCurrentStatus("Connected");
        }
        else {
            setCurrentStatus("Bad");
        }
    };

    function statusToColor(status) {
        if (status == "Connected") return "connected";

        return "bad";
    }

    //return <Navbar.Text>{currentStatus}</Navbar.Text>
    return <span title={currentStatus} class={"dot " + statusToColor(currentStatus)}></span>
};