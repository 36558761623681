import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import DataTable from './DataTable/datatable.component';
import NoFilter from './DataTable/datatable.nofilter';
import SelectFilter from './DataTable/datatable.selectfilter';
import JobDialog from './Dialogs/JobDialog';

import NotifierContext from './Contexts/NotificationContext';


function JobsTable({ jobs, setJobs, loading, error }) {

    const [selectedJob, setSelectedJob] = useState(null);
    const [showJobDialog, setShowJobDialog] = useState(false);

    const tableColumns = useMemo(() => [
        {
            Header: 'Valve',
            accessor: 'valveName',
            filter: 'fuzzyText'
        },
        {
            Header: 'Task',
            accessor: 'taskName',
            filter: 'fuzzyText'
        },
        {
            Header: 'Description',
            accessor: 'description',
            filter: 'fuzzyText'
        },
        {
            Header: 'Required operation',
            accessor: 'action',
            Filter: SelectFilter
        },
        {
            Header: () => <div className="text-center">Command time</div>,
            accessor: 'utcCreatedDate',
            Cell: (props) => {
                return (
                    <div className="text-center">
                        {
                            new Date(props.value).toLocaleDateString('en-GB', {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit"
                            })
                        }
                    </div>
                );
            },
            Filter: NoFilter
        },
        {
            accessor: 'state',
            Header: () => <div className="text-center">Job status</div>,
            Filter: SelectFilter
        },
        {
            id: 'openDialog',
            accessor: 'id',
            Header: () => <div className="text-center">Action</div>,
            Cell: (props) => {
                let disabled = props.row.original.state === "New" || props.row.original.state === "Completed";
                return (
                    <div className="text-center">
                        <Button variant="outline-primary" size="sm" className="table-btn" disabled={disabled} onClick={() => openJobDialog(props.row.original)}>Open</Button>
                    </div>
                );
            },
            Filter: NoFilter
        }
    ], []);

    const onJobUpdate = (data) => {

        var jobUpdate = data.detail.data;
        if (jobUpdate.State === "New" || jobUpdate.action === "NoActionRequired") {
            return;
        }

        updateJob(data);
    }

    const onNewJob = (data) => {
        var jobUpdate = data.detail.data;
        if (jobUpdate.State === "New" || jobUpdate.action === "NoActionRequired") {
            return;
        }

        setJobs(...jobs, jobUpdate);
    }

    var notifier = useContext(NotifierContext);

    // Get jobs and set signalR updates
    useEffect(() => {
        notifier.on("jobUpdate", onJobUpdate);
        notifier.on("newJob", onNewJob);

        return function cleanup() {
            notifier.off("jobUpdate", onJobUpdate);
            notifier.off("newJob", onNewJob);
        };
    }, [notifier]);



    // Set selected job and open job dialog
    const openJobDialog = (job) => {
        console.log("Selected job", job);

        setSelectedJob(job);
        setShowJobDialog(true);
    }

    // Close job dialog and clear selected job
    const closeJobDialog = () => {
        setShowJobDialog(false);
        setSelectedJob(null);
    }

    // When a job is updated, update the selected job and the table
    const updateJob = (job) => {
        if (job) {
            const jobListWithUpdatedJob = jobs.map(x => x.id === job.id ? { ...x, ...job } : x);
            setJobs(jobListWithUpdatedJob);
        }
    }

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    const initialState = {
        pageSize: 20,
        sortBy: [
            {
                id: 'utcCreatedDate',
                desc: false
            }
        ]
    }

    const tableId = "JobsList";

    return jobs && jobs.length > 0
        ?
        <>
            <DataTable id={tableId} tableColumns={tableColumns} tableData={jobs} initialState={initialState} />
            {selectedJob !== null &&
                <JobDialog show={showJobDialog} closeDialog={closeJobDialog} job={selectedJob} updateJob={updateJob} />
            }
            {error &&
                <Alert variant="danger">Something went wrong. Please try again.</Alert>
            }
        </>
        :
        <div className="d-flex flex-column mt-5">
            <h4 className="text-center">No Jobs to show</h4>
        </div>
}

export default JobsTable;