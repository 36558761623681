import { useState, useEffect } from 'react';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';
import useAlert from '../components/Alerts/useAlert';

export function useGetTasks() {

    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const { setAlert } = useAlert();

    useEffect(() => {
        getTasks();
    }, []);

    async function getTasks() {

        await fetchDataAuthenticated("api/activeTasks")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setTasks(data);
            })
            .catch(error => {
                console.error();
                setAlert("danger", "Error", "Failed to get tasks", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { tasks, loading }
}

export function useGetTask(taskName) {

    const [task, setTask] = useState([]);
    const [loading, setLoading] = useState(true);
    const { setAlert } = useAlert();

    useEffect(() => {
        getTask();
    }, []);

    async function getTask() {

        await fetchDataAuthenticated(`api/activeTasks/${taskName}`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setTask(data);
            })
            .catch(error => {
                console.error();
                setAlert("danger", "Error", "Failed to get tasks", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { task, loading }
}