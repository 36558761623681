import { useState, useEffect } from 'react';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';

export function useGetValves() {

    const [loading, setLoading] = useState(true);
    const [valves, setValves] = useState([]);

    useEffect(() => {
        getValves();
    }, []);

    async function getValves() {

        await fetchDataAuthenticated("api/valves")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setValves(data);
            })
            .catch(error => {
                console.error(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { valves, loading }
}